import React, { memo, useState } from 'react';
import styled from '@emotion/styled';
import { Image as CImage, Transformation } from 'cloudinary-react';
import Logo from 'components/header/Logo';
import config from 'config';
import { useUserContext } from 'contexts/UserContext';
import QrSvg from '../../../assets/icons/qr.svg';
import QrModal from './QrModal';

const Wrapper = styled.div`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space.xsmall};
  width: 100%;
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImage = styled(CImage)`
  padding: 8px;
  max-height: 45px;
  max-width: 100%;
  position: relative;
  z-index: 10;
`;

const QRButton = styled.button`
  background: ${({ theme, companyColor }) =>
    companyColor ? companyColor : theme.palette.GREEN};
  border: none;
  border-radius: 50%;
  box-shadow: ${({ theme }) => `2px 2px 6px 0px ${theme.palette.DARK_GRAY}`};
  height: 45px;
  cursor: pointer;
  padding: ${({ theme }) => theme.space.xsmall};
  width: 45px;
`;

const QrIcon = styled(QrSvg)`
  height: 100%;
  width: 100%;
  fill: white;
  padding: 2px;
`;

const HeroHeader = () => {
  const [qrModal, setQrModal] = useState(false);
  const { company } = useUserContext();

  return (
    <>
      <Wrapper>
        <div onClick={() => window.open(company?.logoLink || config.HOST)}>
          <ImgWrapper>
            {company?.logoId ? (
              <StyledImage publicId={company.logoId} secure="true">
                <Transformation quality="auto" fetchFormat="auto" />
              </StyledImage>
            ) : (
              <Logo black large />
            )}
          </ImgWrapper>
        </div>
        <QRButton
          aria-label="QR-code-button"
          companyColor={company?.color}
          onClick={() => setQrModal(true)}
        >
          <QrIcon />
        </QRButton>
      </Wrapper>
      <QrModal open={qrModal} closeModal={() => setQrModal(false)} />
    </>
  );
};

export default memo(HeroHeader);
