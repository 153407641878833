const getCleanUserObject = (user) => {
  return {
    slug: user.slug || '',
    fb_id: user.fb_id || '',
    email: user.email || '',
    profilePicId: user.profilePicId || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    jobTitle: user.jobTitle || '',
    phoneNumber: user.phoneNumber || '',
    companyPage: user.companyPage || '',
    socialMediaLinks:
      user?.socialMediaLinks.map(({ name, url }) => ({ name, url })) || [],
    links: user.links || [],
    companiesAccess: user.companiesAccess || [],
    accountlvl: user.accountlvl || '',
    sessionId: user.sessionId || '',
    stats: user.stats || {},
    paymentData: user.paymentData || {}
  };
};

export default getCleanUserObject;
