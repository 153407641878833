import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import UAParser from 'ua-parser-js';
import track from 'utils/tracking';

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
};

const StyledA = styled(motion.a)`
  align-items: center;
  background: ${({ theme }) => theme.palette.WHITE};
  border-radius: ${({ theme }) => theme.borderRadiusM};
  box-shadow: ${({ theme }) => `${theme.boxShadowSmall} ${theme.palette.GRAY}`};
  color: inherit;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space.medium};
  &:first-child {
    margin-top: 0px;
  }
  padding: ${({ theme }) => theme.space.xsmall};
  text-decoration: none;
  text-align: center;
`;

const LinksSection = ({ t, v, i, trackClick }) => {
  const {
    query: { slug }
  } = useRouter();
  const ua = new UAParser();

  const handleClick = () =>
    track({
      condition: trackClick,
      payload: {
        type: 'custom_link',
        slug,
        index: i
      },
      ua,
      callback: () => window.open(v, '_blank'),
      secondaryCallback: () => (window.location.href = v)
    });

  return (
    <StyledA onClick={handleClick} variants={item} whileHover={{ scale: 1.1 }}>
      {t}
    </StyledA>
  );
};

export default LinksSection;
