import React, { createContext, useContext } from 'react';
import getCleanUserObject from 'utils/getCleanUserObject';

const UserContext = createContext();

const UserContextProvider = ({ user: _user, children }) => {
  const user = getCleanUserObject(_user);
  const company = _user.company;

  return (
    <UserContext.Provider value={{ user, company }}>
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => useContext(UserContext);

export { UserContextProvider, useUserContext };
export default UserContext;
