import React from 'react';
import styled from '@emotion/styled';
import ButtonSkeleton from 'components/button/ButtonSkeleton';
import { useUserContext } from 'contexts/UserContext';
import dynamic from 'next/dynamic';

const ShareContactButton = dynamic(import('./ShareContactButton'), {
  ssr: false,
  loading: () => <ButtonSkeleton />
});

const SaveContactButton = dynamic(import('./SaveContactButton'), {
  ssr: false,
  loading: () => <ButtonSkeleton />
});

const ButtonWrapper = styled.div`
  align-items: center;
  background: ${({ theme, companyColor }) =>
    companyColor ? companyColor : theme.palette.GREEN};
  bottom: 0;
  border-radius: ${({ theme }) =>
    `${theme.borderRadiusXL} ${theme.borderRadiusXL} 0px 0px`};
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 9999;
`;

const SaveContactSection = () => {
  const { company } = useUserContext();

  return (
    <ButtonWrapper companyColor={company?.color}>
      <ShareContactButton />
      <SaveContactButton />
    </ButtonWrapper>
  );
};

export default SaveContactSection;
