import React from 'react';
import styled from '@emotion/styled';
import Skeleton from 'components/Skeleton';

const StyledSkeleton = styled(Skeleton)`
  margin: ${({ theme }) => `0 ${theme.space.small}`};
`;

const ButtonSkeleton = () => <StyledSkeleton width="110px" height="35px" />;

export default ButtonSkeleton;
