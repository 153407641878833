import React from 'react';
import { useTheme, css } from '@emotion/react';
import styled from '@emotion/styled';
import Modal from 'components/Modal';
import { P, H2 } from 'components/typography';
import config from 'config';
import { useUserContext } from 'contexts/UserContext';
import { useRouter } from 'next/router';
import QRCode from 'qrcode.react';

const textStyling = ({ theme }) => css`
  color: ${theme.palette.WHITE};
  text-align: center;
`;

const StyledH2 = styled(H2)`
  ${textStyling}
`;

const StyledP = styled(P)`
  ${textStyling}
`;

const QRWrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.WHITE};
  border-radius: 50%;
  display: flex;
  height: ${({ theme }) => theme.width.xxxsmall};
  justify-content: center;
  margin: ${({ theme }) => `${theme.space.xxlarge} auto`};
  width: ${({ theme }) => theme.width.xxxsmall};
`;

const QrModal = ({ open, closeModal }) => {
  const { asPath } = useRouter();
  const { palette } = useTheme();
  const { company } = useUserContext();

  return (
    <Modal
      open={open}
      onClose={closeModal}
      customBackground={company?.color || palette.GREEN}
    >
      <>
        <StyledH2>Scanna QR-kod för att se profil.</StyledH2>
        <QRWrapper>
          <QRCode value={`${config.HOST}${asPath}`} />
        </QRWrapper>
        <StyledP>
          Använd din telefons kamera och skanna QR-koden ovan för att öppna
          digito-kortet.
        </StyledP>
      </>
    </Modal>
  );
};

export default QrModal;
