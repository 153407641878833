import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
};

const LinkWrapper = styled(motion.a)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme }) => theme.space.small};
`;

const Wrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme }) => theme.space.small};
`;

const SocialMediaLinksIcon = ({ href, icon, id, onClick }) => {
  if (href)
    return (
      <LinkWrapper
        id={id}
        onClick={onClick}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        variants={item}
        whileHover={{ scale: 1.1 }}
      >
        {icon}
      </LinkWrapper>
    );

  return (
    <Wrapper
      id={id}
      onClick={onClick}
      variants={item}
      whileHover={{ scale: 1.1 }}
    >
      {icon}
    </Wrapper>
  );
};

export default SocialMediaLinksIcon;
