import React, { memo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useUserContext } from 'contexts/UserContext';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import getCleanUrl from 'utils/getCleanUrl';
import EnvelopeSvg from '../../../assets/icons/envelope.svg';
import MonitorSvg from '../../../assets/icons/monitor.svg';
import PhoneSvg from '../../../assets/icons/phone.svg';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.WHITE};
  border-radius: ${({ theme }) => theme.borderRadiusM};
  box-shadow: ${({ theme }) => `${theme.boxShadowSmall} ${theme.palette.GRAY}`};
  display: flex;
  justify-content: center;
  margin: ${({ theme }) =>
    `${theme.space.xxlarge} ${theme.space.medium} ${theme.space.small}`};
  padding: ${({ theme }) => `${theme.space.large} ${theme.space.xsmall}`};
`;

const ContentWrapper = styled.div`
  max-width: 100%;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  padding: ${({ theme }) => `${theme.space.xsmall} 0`};
`;

const iconStyle = ({ theme }) => css`
  height: ${theme.iconHeight.small};
  margin: ${`0 ${theme.space.xsmall}`};
  fill: ${theme.palette.BLACK};
`;

const EnvelopeIcon = styled(EnvelopeSvg)`
  ${iconStyle}
`;

const PhoneIcon = styled(PhoneSvg)`
  ${iconStyle}
`;

const MonitorIcon = styled(MonitorSvg)`
  ${iconStyle}
`;

const A = styled.a`
  color: ${({ theme }) => theme.palette.BLACK};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContactInfoCard = () => {
  const {
    user: { email, phoneNumber, companyPage }
  } = useUserContext();

  if (!email && !phoneNumber && !companyPage) {
    return null;
  }

  return (
    <Wrapper>
      <ContentWrapper>
        {email && (
          <Row>
            <EnvelopeIcon />
            <A href={`mailto:${email}`}>{email}</A>
          </Row>
        )}
        {phoneNumber && (
          <Row>
            <PhoneIcon />
            <A href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</A>
          </Row>
        )}
        {companyPage && (
          <Row>
            <MonitorIcon />
            <A href={companyPage} rel={'noopener noreferrer'} target={'_blank'}>
              {getCleanUrl(companyPage)}
            </A>
          </Row>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default memo(ContactInfoCard);
