import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import CloseBlackSvg from '../assets/icons/close-black.svg';
import CloseWhiteSvg from '../assets/icons/close-white.svg';

const absoluteStyle = css`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Button = styled.button`
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 6px;
  ${({ absolute }) => absolute && absoluteStyle}
`;

const closeIconStyle = css`
  height: 20px;
  width: 20px;
`;

const CloseWhiteIcon = styled(CloseWhiteSvg)`
  ${closeIconStyle}
`;

const CloseBlackIcon = styled(CloseBlackSvg)`
  ${closeIconStyle}
`;

const CloseButton = ({ absolute = false, black = false, onClick }) => (
  <Button absolute={absolute} aria-label="close-button" onClick={onClick}>
    {black ? <CloseBlackIcon /> : <CloseWhiteIcon />}
  </Button>
);

export default CloseButton;
