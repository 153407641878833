import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import LoadingDots from 'components/LoadingDots';
import MetaTags from 'components/MetaTags';
import { UserContextProvider } from 'contexts/UserContext';
import PublicProfile from 'features/publicProfile/PublicProfile';
import { useRouter } from 'next/router';
import { getAllUserSlugs, getUserBySlug } from '../api';

const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

const PublicProfilePage = ({ user, notFound }) => {
  const router = useRouter();

  useEffect(() => {
    if (notFound) {
      router.push('/404');
    }
  }, []);

  if (router.isFallback) {
    return (
      <LoadingWrapper>
        <LoadingDots />
      </LoadingWrapper>
    );
  }

  if (!user) return null;

  return (
    <>
      <MetaTags
        title={`@${user.slug}`}
        description="Hantera och dela dina sociala kanaler på en och samma plattform"
        url={`https://digito.se/${user.slug}`}
      />
      <UserContextProvider user={user}>
        <PublicProfile />
      </UserContextProvider>
    </>
  );
};

PublicProfilePage.layoutOptions = { noLayout: true };

export async function getStaticPaths() {
  const data = await getAllUserSlugs();
  const { slugs } = data;
  const paths = slugs.map((slug) => ({ params: { slug } }));

  return { paths, fallback: true };
}

export async function getStaticProps({ params }) {
  let user;
  try {
    user = await getUserBySlug({ slug: params.slug });
  } catch (e) {
    return {
      props: { notFound: true },
      revalidate: 60
    };
  }

  return {
    props: { user },
    revalidate: 60
  };
}

export default PublicProfilePage;
