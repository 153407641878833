import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useUserContext } from 'contexts/UserContext';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import UAParser from 'ua-parser-js';
import track from 'utils/tracking';
import FacebookSvg from '../../assets/icons/facebook.svg';
import GithubSvg from '../../assets/icons/github.svg';
import InstagramSvg from '../../assets/icons/instagram.svg';
import LinkedInSvg from '../../assets/icons/linkedin.svg';
import PinterestSvg from '../../assets/icons/pinterest.svg';
import SnapchatSvg from '../../assets/icons/snapchat.svg';
import SoundcloudSvg from '../../assets/icons/soundcloud.svg';
import SpotifySvg from '../../assets/icons/spotify.svg';
import TiktokSvg from '../../assets/icons/tiktok.svg';
import TwitterSvg from '../../assets/icons/twitter.svg';
import YoutubeSvg from '../../assets/icons/youtube.svg';
import SocialMediaLinksIcon from './SocialMediaLinksIcon';

const iconStyle = ({ theme }) => css`
  height: ${theme.iconHeight.large};
  width: auto;
`;

const LinkedInIcon = styled(LinkedInSvg)`
  ${iconStyle}
`;
const InstagramIcon = styled(InstagramSvg)`
  ${iconStyle}
`;
const FacebookIcon = styled(FacebookSvg)`
  ${iconStyle}
`;
const TwitterIcon = styled(TwitterSvg)`
  ${iconStyle}
`;
const GithubIcon = styled(GithubSvg)`
  ${iconStyle}
`;

const PinterestIcon = styled(PinterestSvg)`
  ${iconStyle}
`;

const SnapchatIcon = styled(SnapchatSvg)`
  ${iconStyle}
`;

const YoutubeIcon = styled(YoutubeSvg)`
  ${iconStyle}
`;

const SpotifyIcon = styled(SpotifySvg)`
  ${iconStyle}
`;

const TiktokIcon = styled(TiktokSvg)`
  ${iconStyle}
`;

const SoundcloudIcon = styled(SoundcloudSvg)`
  ${iconStyle}
  height: 25px;
`;

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const Wrapper = styled(motion.div)`
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadiusM};
  border: ${({ theme }) => `2px solid ${theme.palette.DARK_GRAY}`};
  flex-wrap: wrap;
  justify-content: center;
  margin: ${({ theme }) => `${theme.space.xlarge} ${theme.space.large} 0`};
`;

const getSocialMediaIcon = (name) => {
  switch (name) {
    case 'linkedin':
      return <LinkedInIcon />;
    case 'instagram':
      return <InstagramIcon />;
    case 'facebook':
      return <FacebookIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'github':
      return <GithubIcon />;
    case 'pinterest':
      return <PinterestIcon />;
    case 'snapchat':
      return <SnapchatIcon />;
    case 'youtube':
      return <YoutubeIcon />;
    case 'spotify':
      return <SpotifyIcon />;
    case 'tiktok':
      return <TiktokIcon />;
    case 'soundcloud':
      return <SoundcloudIcon />;
    default:
      return null;
  }
};

const SocialMediaLinks = () => {
  const {
    query: { slug }
  } = useRouter();
  const {
    user: { accountlvl, socialMediaLinks }
  } = useUserContext();
  const ua = new UAParser();

  if (socialMediaLinks.length < 1) {
    return null;
  }

  const handleClick = (sm) =>
    track({
      condition: accountlvl > 1,
      payload: {
        type: 'social_media_link',
        slug,
        name: sm.name
      },
      ua,
      callback: () => window.open(sm.url, '_blank'),
      secondaryCallback: () => (window.location.href = sm.url)
    });

  return (
    <Wrapper animate="show" initial="hidden" variants={container}>
      {socialMediaLinks.map((sm) => (
        <SocialMediaLinksIcon
          key={sm.name}
          id={sm.name}
          onClick={() => handleClick(sm)}
          icon={getSocialMediaIcon(sm.name)}
        />
      ))}
    </Wrapper>
  );
};

export default SocialMediaLinks;
