import React from 'react';
import styled from '@emotion/styled';
import Skeleton from 'components/Skeleton';

const StyledSkeleton = styled(Skeleton)`
  height: 300px;
  border-radius: ${({ theme }) => theme.borderRadiusM};
  box-shadow: ${({ theme }) => `${theme.boxShadowSmall} ${theme.palette.GRAY}`};
  margin: ${({ theme }) =>
    `${theme.space.xxlarge} ${theme.space.medium} ${theme.space.small}`};
`;

const MapSkeleton = () => <StyledSkeleton />;

export default MapSkeleton;
