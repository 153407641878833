import React from 'react';
import styled from '@emotion/styled';
import { Image, Transformation } from 'cloudinary-react';

const Wrapper = styled.div`
  border-radius: 50%;
  height: ${({ theme }) => theme.imgSize.small};
  overflow: hidden;
  padding-bottom: ${({ theme }) => theme.imgSize.small};
  position: relative;
  width: ${({ theme }) => theme.imgSize.small};
`;

const StyledImage = styled(Image)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ImageComponent = ({ src }) => (
  <Wrapper>
    <StyledImage publicId={src} alt="profile-img" secure="true">
      <Transformation quality="auto" fetchFormat="auto" />
    </StyledImage>
  </Wrapper>
);

export default ImageComponent;
