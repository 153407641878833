import styled from '@emotion/styled';
import MuiModal from '@material-ui/core/Modal';
import CloseButton from './CloseButton';

const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme, customBackground }) =>
    customBackground ? customBackground : theme.palette.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  margin: auto;
  max-width: ${({ theme }) => theme.width.small};
  padding: ${({ theme }) => `${theme.space.xxxxlarge} ${theme.space.medium}`};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
`;

const Modal = ({ customBackground, open, onClose, children }) => {
  return (
    <MuiModal style={{ zIndex: 999 }} open={open} onClose={onClose}>
      <Wrapper customBackground={customBackground}>
        <CloseButton absolute onClick={onClose} black={!customBackground} />
        {children}
      </Wrapper>
    </MuiModal>
  );
};

export default Modal;
