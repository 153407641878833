import React, { memo } from 'react';
import styled from '@emotion/styled';
import { H2, H3 } from 'components/typography';
import { useUserContext } from 'contexts/UserContext';
import Image from '../../../components/Image';
import HeroHeader from './HeroHeader';

const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme, companyColor }) =>
    companyColor ? companyColor : theme.palette.GREEN};
  color: ${({ theme }) => theme.palette.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-bottom-right-radius: ${({ theme }) => theme.borderRadiusXL};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadiusXL};
`;

const StyledH2 = styled(H2)`
  font-weight: 100;
  margin-bottom: ${({ theme }) => theme.space.xxsmall};
  text-align: center;
`;

const StyledH3 = styled(H3)`
  margin-top: 0;
  text-align: center;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.space.large} ${theme.space.xsmall}`};
`;

const Hero = () => {
  const {
    user: { profilePicId, firstName, lastName, jobTitle },
    company
  } = useUserContext();

  return (
    <>
      <Wrapper companyColor={company?.color}>
        <HeroHeader />
        <Column>
          {profilePicId && <Image src={profilePicId} alt="profilePic" />}
          <StyledH2>{`${firstName} ${lastName}`}</StyledH2>
          <StyledH3>{jobTitle}</StyledH3>
        </Column>
      </Wrapper>
    </>
  );
};

export default memo(Hero);
