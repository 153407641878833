import React from 'react';
import styled from '@emotion/styled';
import MapSkeleton from 'components/map/MapSkeleton';
import SocialMediaLinks from 'components/socialMediaLinks/SocialMediaLinks';
import Layout from 'layout/Layout';
import dynamic from 'next/dynamic';
import ContactInfoCard from './components/ContactInfoCard';
import Hero from './components/Hero';
import LinksSection from './components/LinksSection';
import SaveContactSection from './components/SaveContactSection';

const CompanyBio = dynamic(import('./components/CompanyBio'), {
  ssr: false,
  loading: () => null
});

const CompanyMapLeafLet = dynamic(import('components/map/CompanyMapLeafLet'), {
  ssr: false,
  loading: () => <MapSkeleton />
});

const Wrapper = styled.div`
  margin: auto;
  max-width: ${({ theme }) => theme.width.xsmall};
  padding-bottom: ${({ theme }) => theme.space.xxxxlarge};
`;

const PublicProfile = () => (
  <>
    <Hero />
    <Layout
      backgroundColor={'#f4f4f4'}
      fullHeight={false}
      header={false}
      toppPadding={'0px'}
    >
      <Wrapper>
        <ContactInfoCard />
        <SocialMediaLinks />
        <LinksSection />
        <CompanyBio />
        <CompanyMapLeafLet />
      </Wrapper>
    </Layout>
    <SaveContactSection />
  </>
);

export default PublicProfile;
