import { userTracking } from 'api';

const ASYNC_TRACK_BROWSERS = ['facebook', 'instagram'];

const track = async ({
  asyncTrack = true,
  condition,
  payload,
  ua,
  callback,
  secondaryCallback
}) => {
  if (
    asyncTrack &&
    ASYNC_TRACK_BROWSERS.includes(ua?.getBrowser()?.name?.toLowerCase())
  ) {
    if (condition) await userTracking(payload);
    secondaryCallback();
  } else {
    if (condition) userTracking(payload);
    callback();
  }
};

export default track;
