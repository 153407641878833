import React from 'react';
import styled from '@emotion/styled';
import Link from 'components/LinksSection.Link';
import { useUserContext } from 'contexts/UserContext';
import { motion } from 'framer-motion';

const Wrapper = styled(motion.div)`
  margin: ${({ theme }) =>
    `${theme.space.xxlarge} ${theme.space.medium} ${theme.space.small}`};
  display: flex;
  flex-direction: column;
`;

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const LinksSection = () => {
  const {
    user: { accountlvl, links }
  } = useUserContext();

  if (links.length < 1) {
    return null;
  }

  return (
    <Wrapper animate="show" initial="hidden" variants={container}>
      {links.map((p, i) => (
        <Link key={`link-${i}`} {...p} trackClick={accountlvl > 1} />
      ))}
    </Wrapper>
  );
};

export default LinksSection;
